<template>
  <div>
    <Loading
      :active.sync="isLoading"
      :can-cancel="false"
      :is-full-page="true"
      :opacity="0.5"
      loader="dots"
    ></Loading>
    <div class="d-flex justify-content-center" v-if="!isLoading">
      <div class="technician-card my-5">
        <img
          class="edit-address"
          src="@/assets/edit.svg"
          @click="editAddress()"
          v-if="maintenanceRole == 'high-technician' || maintenanceRole == 'admin'"
        />
        <div class="address d-flex flex-column align-items-start">
          <div class="topic-text">
            <p>{{ $t("maintenance_report.address") }}</p>
          </div>
          <textarea
            v-model="editedData.address"
            v-if="editting"
            class="my-1 w-100"
          ></textarea>
          <p class="content-text" v-else>{{ technicianData.address }}</p>
        </div>

        <div class="contact d-flex flex-column align-items-start mt-3">
          <div class="topic-text">
            <p>{{ $t("maintenance_report.contact-dep") }}</p>
          </div>
          <div class="py-2 d-flex gap-2 align-items-center">
            <img
              src="../../assets/Maintenancing/technician-department.png"
              alt="department"
            />
            <b-form-input
              type="text"
              v-model="editedData.department"
              v-if="editting"
            />
            <div class="content-text " v-else>
              {{ technicianData.department }}
            </div>
          </div>
          <div class="py-2 d-flex gap-2 align-items-center">
            <img
              src="../../assets/Maintenancing/technician-mail.png"
              alt="email"
            />
            <b-form-input
              type="text"
              v-model="editedData.email"
              v-if="editting"
            />
            <div class="content-text" v-else>
              <a
                class="contact-click"
                :href="`mailto:${technicianData.email}`"
                target="_blank"
                >{{ technicianData.email }}</a
              >
            </div>
          </div>
          <div class="py-2 d-flex gap-2 align-items-center">
            <img
              src="../../assets/Maintenancing/technician-phone.png"
              alt="phone"
            />
            <b-form-input
              type="text"
              v-model="editedData.telephone"
              v-if="editting"
            />
            <div class="content-text" v-else>
              <a
                class="contact-click"
                :href="`tel:${technicianData.telephone}`"
                >{{ technicianData.telephone }}</a
              >
            </div>
          </div>
        </div>
        <div
          class="d-flex justify-content-center"
          :class="editting == true ? 'd-none' : ''"
        >
          <hr class="line" />
        </div>
        <!-- ข้อมูลช่างเล็ก  -->
        <div
          class="contact d-flex flex-column align-items-start my-3"
          :class="editting == true ? 'd-none' : ''"
        >
          <div class="topic-text">
            <p>{{ $t("maintenance_report.contact-tech") }}</p>
          </div>
            <div v-for="technician in technicianList"
            :key="technician.id" class="mb-3">
            <div class="py-2 d-flex gap-2 align-items-center">
              <img
                src="../../assets/Maintenancing/technician-department.png"
                alt="department"
              />
              <div class="content-text">
                {{ technician.name }}
              </div>
            </div>
            <div class="py-2 d-flex gap-2 align-items-center">
              <img
                src="../../assets/Maintenancing/technician-mail.png"
                alt="email"
              />
              <div class="content-text">
                <a
                  class="contact-click"
                  :href="`mailto:${technician.email}`"
                  target="_blank"
                  >{{ technician.email }}</a
                >
              </div>
            </div>
            <div class="py-2 d-flex gap-2 align-items-center">
              <img
                src="../../assets/Maintenancing/technician-phone.png"
                alt="phone"
              />
              <div class="content-text">
                <a class="contact-click" :href="`tel:${technician.telephone}`">{{
                  technician.phone
                }}</a>
              </div>
            </div>
          </div>
        </div>
        <!-- button -->
        <div class="d-flex mt-2 justify-content-center gap-3" v-if="editting">
          <div class="cancel" @click="cancelChange()">
            {{ $t("maintenance_report.cancel") }}
          </div>
          <div class="save" @click="saveChange()">
            {{ $t("maintenance_report.save") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import { authHeader } from "@/store/actions";
import baseUrl from "@/util/backend";
import Loading from "vue-loading-overlay";


export default {
  components: {
    Loading,
  },
  data() {
    return {
      isLoading: false,
      id_company: null,
      editting: false,
      technicianData: {},
      editedData: {},
      technicianList: [
      ],
    };
  },
  async mounted() {
    this.id_company = await this.$store.dispatch("getCompanyIdCookie");
    this.getTechnicianData();
    this.getTechnicianList();
  },
  methods: {
    editAddress() {
      this.editedData = { ...this.technicianData };
      this.editting = !this.editting;
    },
    async saveChange() {
      await this.editTechnicianData();
      this.editting = !this.editting;
      this.getTechnicianData();
    },
    cancelChange() {
      this.editedData = {};
      this.editting = !this.editting;
      this.getTechnicianData();
    },
    async getTechnicianData() {
      this.isLoading = true;
      try {
        const res = await axios.get(
          `${baseUrl()}company/${this.id_company}/technicianInfo`,
          {
            headers: authHeader().headers,
          }
        );
        // console.log(res.data);
        this.technicianData = res.data.message[0];
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (error.response && error.response.data && error.response.data.message) {
          alert(error.response.data.message);
        } else {
          alert(error.message || error)
        }
        console.log(error);
      }
    },
    async getTechnicianList() {
      this.isLoading = true;
      try {
        const res = await axios.get(
          `${baseUrl()}company/${this.id_company}/technicianList`,
          {
            headers: authHeader().headers,
          }
        );
        // console.log(res.data);
        this.technicianList = res.data.message;
        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (error.response && error.response.data && error.response.data.message) {
          alert(error.response.data.message);
        } else {
          alert(error.message || error)
        }
        console.log(error);
      }
    },
    async editTechnicianData() {
      this.isLoading = true;
      try {
        const res = await axios.put(
          `${baseUrl()}company/${this.id_company}/technicianInfo`,
          {
            technician_info: {
              address: this.editedData.address,
              department: this.editedData.department,
              email: this.editedData.email,
              telephone: this.editedData.telephone,
            },
            index: 0,
          },
          {
            headers: authHeader().headers,
          }
        );
        // console.log(res.data);
      this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        if (error.response && error.response.data && error.response.data.message) {
          alert(error.response.data.message);
        } else {
          alert(error.message || error)
        }
        console.log(error);
      }

    },
  },
  computed: {
    ...mapGetters({
      maintenanceRole: "maintenance/maintenanceRole",
    }),
  },
  // watch: {
  // technicianData: {
  //   handler: function(val, oldVal) {
  //     console.log(val);
  //     console.log(oldVal);
  //   },
  //   deep: true,
  // },
  // editedData: {
  //   handler: function(val, oldVal) {
  //     console.log(val);
  //     console.log(oldVal);
  //   },
  //   deep: true,
  // },
  // },
};
</script>

<style lang="scss" scoped>
.line {
  border: #000000 1px solid;
  width: 80%;
  align-self: center;
}
.contact-click {
  color: #017afd;
  text-decoration: underline;
}
.contact-click:hover {
  color: #0060c6;
  text-decoration: underline;
}
.content-text {
  width: inherit;
  word-wrap: break-word;
  text-align: left;
  overflow-wrap: anywhere;
}

.cancel {
  background-color: #7c7c7c;
  border-radius: 8px;
  color: #ffffff;
  padding: 8px 15px;
  font-size: 16px;
  width: 50%;
}
.save {
  background-color: #017afd;
  border-radius: 8px;
  color: #ffffff;
  padding: 8px 15px;
  font-size: 16px;
  width: 50%;
}
.edit-address {
  display: block;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 25px;
}
.technician-card {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #7c7c7c;
  padding: 60px 110px;
  width: 45%;
  position: relative;
}
.topic-text {
  font-size: 20px;
  font-weight: 600;
}
input {
  border: 2px solid #7c7c7c !important;
  background-color: #fff;
  width: 100%;
  color: #000;
}
@media (min-width: 1501px) {
  .technician-card {
    padding: 40px 100px;
    width: 30%;
  }
}

@media (max-width: 1500px) {
  .technician-card {
    padding: 30px 55px;
    width: 50%;
  }
}

@media (max-width: 550px) {
  .technician-card {
    padding: 19px 25px;
    width: 70%;
  }
  .topic-text {
    font-size: 16px;
    font-weight: 600;
  }
  .content-text {
    font-size: 14px;
    width: inherit;
    word-wrap: break-word;
    text-align: left;
    overflow-wrap: anywhere;
  }
  .edit-address {
    top: 15px;
    right: 15px;
    width: 17px;
  }
}

@media screen and (max-width: 360px) {
  .technician-card {
    padding: 15px 12px;
    width: 80%;
  }
  .topic-text {
    font-size: 16px;
    font-weight: 600;
  }
  .content-text {
    font-size: 13px;
    width: inherit;
    word-wrap: break-word;
    text-align: left;
    overflow-wrap: anywhere;
  }
  img {
    width: 19px;
  }
  svg {
    width: 19px;
  }
  .edit-address {
    top: 10px;
    right: 10px;
    width: 20px;
  }
}
</style>
